import React,{useEffect} from "react";
import "./App.css";
import mainLogo from'./logo_en.png';

import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { initializeApp } from 'firebase/app'
import {
  getFirestore, collection, getDocs,updateDoc,doc,getDoc
} from 'firebase/firestore'
import { MDBBtn, MDBCard,MDBCardImage, MDBCardBody, MDBCardHeader, MDBCheckbox, MDBCol, MDBInput, MDBListGroup, MDBListGroupItem, MDBRow, MDBTextArea, MDBTypography } from 'mdb-react-ui-kit';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function App() {



  const firebaseConfig = {
    apiKey: "AIzaSyD6ZMI1e5zfRjuz8KQVRhW1b8YNPIDYV0s",
    authDomain: "auzaarpay.firebaseapp.com",
    projectId: "auzaarpay",
    storageBucket: "auzaarpay.appspot.com",
    messagingSenderId: "42080808235",
    appId: "1:42080808235:web:2b46c8a4384eee5845296e",
    measurementId: "G-SYZG2Q94RB"
  };


  initializeApp(firebaseConfig)
  const [orderID, setorderID] = useQueryParam('id', StringParam);
  
  const [order, setOrder] = React.useState({});
  const [orderItems, setOrderItems] = React.useState([]);
  const [completed, setCompleted] = React.useState(false);
  const [name, updateName] = React.useState("");
  const [address, updateAddress] = React.useState("");
  const [customid, updateCustomid] = React.useState("");
  
  const [authToken, updateAuthToken] = React.useState("");
  const [number, updateNumber] = React.useState("");
  const [pincode, updatePincode] = React.useState("");
  const [sector, updateSector] = React.useState("");
  
  const db = getFirestore()
  
  const colRef = collection(db, 'userOrders')
  const docRef = doc(db, 'keys','zoho')
  const itemsColRef = collection(db, 'userOrders/'+orderID+'/items')
  let items=[];
  let orderDetails={};

useEffect(()=>{  



  getDoc(docRef)
  .then(snapshot => {
    updateAuthToken(snapshot.data()['authToken']);
   console.log(snapshot.data())
  })
  .catch(err => {
    console.log(err.message)
  })


  getDocs(colRef)
  .then(snapshot => {
    snapshot.docs.forEach(doc => {
      console.log(doc.id);
      console.log(orderID);
      if(doc.id===orderID)
{
  setOrder(doc.data());
  console.log(doc.data()['customerId']);
  updateCustomid(doc.data()['customerId']);
  }
    })
  })
  .catch(err => {
    console.log(err.message)
  })
  getDocs(itemsColRef)
  .then(snapshot2 => {      
    snapshot2.docs.forEach(doc2 => {
      console.log(doc2.data())
      items.push(doc2.data())
      
    })
    setOrderItems(items);
  })
  .catch(err => {
    console.log(err.message)
  })


  

}, [])

async function saveShippingDetails() {
  if(name.length==0)
  alert("Full Name Field is mandatory")

 else if(number.length==0)
  alert("Phone Number Field is mandatory")

  else if(number.length!=10)
  alert("Please Enter a valid phone number")


  else if(address.length==0)
  alert("Address Field is mandatory")

  else if(pincode.length!=6)
  alert("Please Enter a valid pincode")


  else if(pincode.length==0)
  alert("Pincode Field is mandatory")

 
 else{ var orderDocRef = doc(db,'userOrders', orderID);
  updateDoc(orderDocRef,{
    customerDetails:{"address":address,"name":name,"phone":number,"pincode":pincode,"sector":sector}
    
  } ).then(response => {
    
  }).catch(error =>{
    alert(error.message)
  })}
}



  async function showRazorpay() {
    if(name.length==0)
    alert("Full Name Field is mandatory")
  
   else if(number.length==0)
    alert("Phone Number Field is mandatory")
  
    else if(number.length!=10)
    alert("Please Enter a valid phone number")
  
  
    else if(address.length==0)
    alert("Address Field is mandatory")
  
    else if(pincode.length!=6)
    alert("Please Enter a valid pincode")
  
  
    else if(pincode.length==0)
    alert("Pincode Field is mandatory")
  
    else

{
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }


    const data = await fetch('https://paybackend.auzaarpay.com/razorpay?amount='+order.totalAmount+'&salesOrderID='+order.salesOrderID+'&invoiceID='+order.invoiceID, {
      method: "POST", mode: 'cors',
      body: JSON.stringify({
        "amount": order.totalAmount.substring(0,order.totalAmount.length-3)
       })
    }).then((t) => t.json());

    console.log(data);

    const options = {
      key: "rzp_test_7OGBvwNpxAVNTT",
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: "Donation",
      description: "Thank you for nothing. Please give us some money",
      image: "https://paybackend.auzaarpay.com/logo.svg",
      handler: async function (response) {

        console.log(customid);
        const data = await fetch("https://paybackend.auzaarpay.com/updateAddress?name="+name+"&customerid="+customid+"&authToken="+authToken+"&address="+address+"&pincode="+pincode+"&salesOrderID="+order.salesOrderID+"&amount="+order.totalAmount+"&invoiceID="+order.invoiceID, {
          method: "POST", mode: 'cors',
          body: JSON.stringify({
            "amount": order.totalAmount.substring(0,order.totalAmount.length-3)
           })
        });
       if(data.status==200)
    setCompleted(true);
        alert("Transaction successful");
      },
      prefill: {
        name: "Rajat",
        email: "rajat@rajat.com",
        phone_number: "9899999999",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();}
  }



  return (


order.status!='customer'?<div>Order Already Paid</div>:
  completed?<div>
    Completed
  </div>:  <div className="App">
 <div className="mx-auto mt-5" style={{ maxWidth: '900px' }}>
 <img  src={mainLogo} alt="fireSpot"/>
      <MDBRow>
        <MDBCol md="8" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardHeader className="py-3">
              <MDBTypography tag="h5" className="mb-0">Shipping details</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
              <form>
                <MDBInput label='Full name' type='text'  className="mb-4" value={name} onChange={(e) => updateName(e.target.value)}/>
                <MDBInput label='Contact Number' type='text' className="mb-4" value={number} onChange={(e) => updateNumber(e.target.value)}/>
                <MDBInput label='Address' type='text' className="mb-4" value={address} onChange={(e) => updateAddress(e.target.value)}/>
                <MDBInput label='Pincode' type='text' className="mb-4" value={pincode} onChange={(e) => updatePincode(e.target.value)}/>
                <MDBInput label='Sector' type='text' className="mb-4" value={sector} onChange={(e) => updateSector(e.target.value)}/>

              <MDBBtn  color="primary"size="lg" onClick={saveShippingDetails} block type="button">
                Save Shipping Details
              </MDBBtn>
                {/* <MDBTextArea label='Additional information' rows={4} className="mb-4" /> */}

                {/* <div className="d-flex justify-content-center">
                  <MDBCheckbox name='flexCheck' value='' id='flexCheckChecked' label='Create an account?' defaultChecked />
                </div> */}
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardHeader className="py-3">
              <MDBTypography tag="h5" className="mb-0">Summary</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
            {orderItems.map((element,key) => (
           <MDBRow key={key}>
           <MDBCol md="4">
             <MDBCardImage src={`data:image/jpg;base64,${element['productImage']}`}
               className="rounded-3" style={{ width: '100px' }} alt="Blue Jeans Jacket" />
           </MDBCol>
           <MDBCol md="7" className="ms-3 ">
             <p className="mb-0 text-price text-end">Rs. {element['price']}</p>
             <p className="mb-0 text-descriptions text-end">{element['productName']}</p>
        
             <p className="text-descriptions mt-0 text-end">
               Qty:  <b>1</b>
             </p>
           </MDBCol>
         </MDBRow>
            ))}
            {/* <MDBRow>
                  <MDBCol md="4">
                    <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Vertical/12a.webp"
                      className="rounded-3" style={{ width: '100px' }} alt="Blue Jeans Jacket" />
                  </MDBCol>
                  <MDBCol md="7" className="ms-3 ">
                    <p className="mb-0 text-price text-end">${orderItems.length}.00  {orderItems.length}{order.commission}</p>
                    <p className="mb-0 text-descriptions text-end">Denim jacket </p>
                <p className="text-end" style={{margin:0}}>   <span className="text-descriptions fw-bold ">Black</span> 
                    <span className="text-descriptions fw-bold">UK 8</span></p> 
                    <p className="text-descriptions mt-0 text-end">
                      Qty:  <b>1</b>
                    </p>
                  </MDBCol>
                </MDBRow> */}
              <MDBListGroup flush>
                <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                  Products
                  <span>Rs. {order.totalAmount}</span>
                </MDBListGroupItem>
                <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                  Shipping
                  <span>Rs. 0</span>
                </MDBListGroupItem>
                <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                  <div>
                    <strong>Total amount</strong>
                    {/* <strong>
                      <p className="mb-0">(including VAT)</p>
                    </strong> */}
                  </div>
                  <span><strong>Rs. {order.totalAmount}</strong></span>
                </MDBListGroupItem>
              </MDBListGroup>

              <MDBBtn color="primary" size="lg" onClick={showRazorpay} block>
                Make purchase
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </div>
    
    </div>
  );

  
}

export default App;